export const paths = {
    //login: 'login',

    home: 'home',

    /** who we are */
    aboutUs: 'about-us',
    ourTeam: 'our-team',
    socialResponsibility: 'social-responsibility',
    promHQ: 'prom-hq',
    ourNetwork: 'our-network',
    countryPage: 'country-page',
    ourPartnership: 'our-partnership',

    /** our expertise */
    riskPlanningControl: 'risk-planning-control',
    financialDistribution: 'financial-distribution',
    strategicAdvisory: 'strategic-advisory',
    financialAdvisory: 'financial-advisory',
        anteo: 'financial-advisory_anteo',
        anteoArchive: 'financial-advisory_anteo-archive',
        anteoArticle: 'financial-advisory_anteo-article',
        financialAdvisoryConsultancy: 'financial-advisory_consultancy',
        societaryOrgans: 'financial-advisory_our-team',    // financial-advisory_team
                                    // 29F8 ;	002F ;	MA	#* ( ⧸ → / ) BIG SOLIDUS → SOLIDUS	#  
                                    // https://www.compart.com/en/unicode/U+29F8
                                    // Codice HTML: &#10744;
                                    // Codice CSS: #css_example:after {  content: "\29F8";  } 
                                    // Codice BASH: $ echo -e '\u29F8'
        informationPaths: 'financial-advisory_information-paths',
        informationPathsArchive: 'financial-advisory_information-paths-archive',
    esgClimate: 'esg-climate',
    techSolutions: 'tech-solutions',
    riskTechAndWealthTech: 'risk-tech-and-wealth-tech', // redirects to " techSolutions: 'tech-solutions' "
    saasCloud: 'saas-cloud',
        promAWS: 'prometeia-and-aws',
        promMsAzure: 'prometeia-and-ms-azure',
    appliedDataScienceAndAdvancedAnalytics: 'applied-data-science-and-advanced-analytics',
    riskTech: 'risk-tech',
    wealthTech: 'wealth-tech',

    discoverMore: "discover-more",

    // RnD: 'R-and-D',
    research_bi_reporting: 'research-bi-reporting', //Research, BI & Reporting
    ourStory: 'our-story',
    dataScience: 'data-science',

    // insights: 'insights',
    news: 'news',
    newsArticle: 'news-article',
    newsletter: 'newsletter',
    trendingTopics: 'trending-topics',
    trendingTopicsArticle: 'trending-topics-article',
    trendingTopicsArchive: 'trending-topics-archive',

    /** career */
    ourCulture: 'our-culture',
    masterCorsiLaurea: 'master-corsi-laurea',
    careers: 'careers',
    jobOffer: 'job-offers',
    offer: 'offer',
    alumni: 'alumni',
    alumniUnsubscribe: 'alumni/unsubscribe',
    alumniStories: 'alumni-stories',
    promLife: 'prom-life',

    reservedArea: 'reserved-area',
    article: 'article',


    activateAccount: 'activate-account',
    searchResults: 'search-results',
    pageNotFound: '404'
}

export const specialPaths = {
    archetipiEroici: 'archetipi-eroici'
}

export const separator = "__";

export const noBilingual = [
    paths.anteo,
    paths.anteoArchive,
    paths.anteoArticle,
    paths.informationPaths,
    paths.informationPathsArchive,
]

export const simTree: string[] = [
    paths.financialAdvisory,
    paths.anteo,
    paths.anteoArchive,
    paths.anteoArticle,
    paths.financialAdvisoryConsultancy,
    paths.societaryOrgans,
    paths.informationPaths,
    paths.informationPathsArchive,

    "privacy-policy-sim",
    "whistleblowing",
    "anteo-newsletter",
    "send-cv-sim",
    "general-information",
]

export const flattenedID: string[] = [
    paths.countryPage,
    paths.financialAdvisoryConsultancy,
    paths.anteo,
    paths.anteoArticle,
    paths.discoverMore,
    paths.alumniStories,
    paths.promLife,
    paths.article,
    paths.informationPaths
];

// export const footerDocsIDs: string[] = [
//     /** SPA + SIM */
//     "cookie",
//     "company-information",
//     "ethics",

//     "app-atlante",
//     "app-career",

//     /** SPA */
//     "privacy-policy",
//     "web-site-information",
//     "newsletter-information",
//     "terms-of-use",
//     "publishing",
//     "send-cv",
//     "quality",

//     /** SIM */
//     "privacy-policy-sim",
//     "general-information",
//     "send-cv-sim",
//     "anteo-newsletter",
//     "whistleblowing",
// ]

export const shortFooterDocs: string[] = [
    "privacy-policy",
    "privacy-policy-sim",
    "company-information"
]


const _oldPermalinksITA: string[] = [
    "https://www.prometeia.it/atlante/crisi-bancarie-ruolo-sostegno-pubblico",
    "https://www.prometeia.it/atlante/ester-cos-e-nuovo-tasso",
    "https://www.prometeia.it/news/financial-modelling-insights-the-inflation-index",
    "https://www.prometeia.it/news/banks-sailing-in-uncertain-waters",
    "https://www.prometeia.it/news/risk-sharing-and-risk-reduction-in-the-euro-area-banking-sector",
    "https://www.prometeia.it/atlante/senior-non-preferred-bond-cosa-sono",
    "https://www.prometeia.it/news/eu-wide-stress-test-nfci-what-role-for-internal-models",
    "https://www.prometeia.it/news/fair-value-of-defaultable-bonds-and-bank-loans-a-practitioners-guide",
    "https://www.prometeia.it/news/towards-cfo-cro-collaboration-model-real-challenges-for-banks",
    "https://www.prometeia.it/news/basel-3-or-4-where-do-we-stand",
    "https://www.prometeia.it/atlante/banche-nuove-regole-europee-sostegno-credito",
    "https://www.prometeia.it/news/ECB-liquidity-stress-test-2019-white-paper",
    "https://www.prometeia.it/prometeia/atlante/bce-tltro-terzo-round-quelle-precedenti-hanno-funzionato",
    "https://www.prometeia.it/news/banche-business-model-che-verra",
    "https://www.prometeia.it/news/how-will-credit-spread-risk-in-the-banking-book-be-put-into-practice-prometeia-survey",
    "https://www.prometeia.it/prometeia/atlante/altalena-spread-quali-impatti-patrimonio-banche-italiane",
    "https://www.prometeia.it/prometeia/atlante/prospettive-economiche-peggioramento-ifrs9-quale-impatto-su-accantonamenti",
    "https://www.prometeia.it/news/does-digitalisation-increase-bank-profitability",
    "https://www.prometeia.it/news/why-italian-banks-pay-higher-returns-to-investors",
    "https://www.prometeia.it/atlante/fondi-tltro-iii-non-sempre-momento-giusto-per-richiederli",
    "https://www.prometeia.it/atlante/quanto-fanno-male-tassi-bassi-settore-bancario",
    "https://www.prometeia.it/atlante/se-banche-italiane-facessero-pagare-conto-corrente",
    "https://www.prometeia.it/atlante/banche-italiane-rischiosita-credito-diminuisce-cosa-succede-se-ciclo-rallenta",
    "https://www.prometeia.it/atlante/do-low-interest-rates-really-hurt-banks-profitability",
    "https://www.prometeia.it/news/how-banks-respond-ECB-validation-reporting-model-risk-management-requirements-findings",
    "https://www.prometeia.it/atlante/bce-debutta-stile-lagarde-saro-me-stessa-probabilmente-diversa",
    "https://www.prometeia.it/atlante/credito-imprese-di-nuovo-caduta",
    "https://www.prometeia.it/atlante/coronavirus-rialzo-spread-ma-bund-scivola-verso-minimi",
    "https://www.prometeia.it/atlante/coronavirus-mosse-spuntate-bce-perche-ai-mercati-non-sono-piaciute",
    "https://www.prometeia.it/atlante/coronavirus-come-utilizzare-al-meglio-nuova-liquidita-bce",
    "https://www.prometeia.it/atlante/cura-italia-per-banche-prima-analisi",
    "https://www.prometeia.it/atlante/rischi-settore-bancario-italiano-evidenze-ricavate-da-andamenti-azionari",
    "https://www.prometeia.it/atlante/covid-19-anche-commissione-europea-in-prima-linea-per-sostegno-credito",
    "https://www.prometeia.it/atlante/covid-19-impatti-su-rettifiche-capitale-cosa-hanno-detto-trimestrali-banche-europee",
    "https://www.prometeia.it/atlante/banche-sistemi-pagamento-lezioni-dalla-pandemia",
    "https://www.prometeia.it/atlante/green-stocks-alla-prova-crisi-da-covid-19",
    "https://www.prometeia.it/atlante/banche-italiane-6-nuove-tendenze-dettate-da-crisi-covid-19",
    "https://www.prometeia.it/news/ai-risk-management-fattori-abilitanti-use-case-sfide-future",
    "https://www.prometeia.it/atlante/delicata-partita-banche-italiane-prestiti-in-moratoria",
    "https://www.prometeia.it/atlante/diverse-performance-banche-europee-attravers-lente-business-model",
    "https://www.prometeia.it/news/climate-change-risk-across-EU-banking-system-prometeia-survey",
    "https://www.prometeia.it/atlante/central-bank-digital-currencies-what-is-at-stake-fot-the-banking-system",
    "https://www.prometeia.it/atlante/moratorie-garanzie-europa-chi-cosa-quanto",
    "https://www.prometeia.it/atlante/banche-italiane-europee-un-anno-dopo",
    "https://www.prometeia.it/atlante/banche-italiane-anno-dopo-quattro-trend-consolidati-dopo",
    "https://www.prometeia.it/news/credit-risk-models-the-power-of-transactional-data-and-machine-learning",
    "https://www.prometeia.it/ricerca/dataviz/disoccupazione-usa-ai-minimi-cosa-fara-fed",
    "https://www.prometeia.it/atlante/edilizia-residenziale-internazionale-rallenta-passo-2018",
    "https://www.prometeia.it/en/atlante/protectionism-negative-sum-game",
    "https://www.prometeia.it/atlante/oro-motivi-fluttuazioni-2018-2019",
    "https://www.prometeia.it/ricerca/brief/febbraio2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/usmca-nuovo-nafta",
    "https://www.prometeia.it/atlante/fiscal-policy-can-help-countries-adapt-climate-change",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/disparita-regionali-male-italia-o-europa",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/auto-elettrica-traina-corsa-litio",
    "https://www.prometeia.it/ricerca/dataviz/auto-quote-export-verso-usa-2019",
    "https://www.prometeia.it/ricerca/brief/maggio2019",
    "https://www.prometeia.it/ricerca/dataviz/debito-pubblico-paesi-membri-detenuto-da-istituzioni-europee-perche-importante",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/guerra-commerciale-usa-cina-ruolo-italia",
    "https://www.prometeia.it/atlante/sessantadue-anni-dopo-roma-perche-abbiamo-ancora-bisogno-dei-trattati",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/electric-vehicles-europa-rapida-transizione",
    "https://www.prometeia.it/news/highlights-rapporto-di-previsione-prometeia-luglio-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/polonia-confermata-solidita-economia/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/jefta-che-cosa-e/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/news/rapporto-previsione-settembre-2019-highlights",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/dazi-usa-chi-rischia-in-ue",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/petrolio-buon-mercato-quale-impatto-su-shale-oil-statunitense",
    "https://www.prometeia.it/ricerca/brief/novembre2019",
    "https://www.prometeia.it/news/rapporto-previsione-dicembre-2019-highlights",
    "https://www.prometeia.it/atlante/asia-top-nel-ranking-paesi-maggior-complessita-economica",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/accordo-dazi-usa-cina-gennaio-2020",
    "https://www.prometeia.it/atlante/quali-rischi-corre-uem-per-esposizione-mercati-finanziari-usa",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/coronavirus-tra-crisi-sanitaria-minaccia-per-economia-mondiale",
    "https://www.prometeia.it/dataviz/export-germania-cause-debolezza",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/petrolio-mancato-accordo-opec-russia-conseguenze-mercati",
    "https://www.prometeia.it/atlante/coronavirus-aiuto-intelligenza-artificiale",
    "https://www.prometeia.it/news/papporto-previsione-marzo-2020-highlights",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/covid-apertura-scambi-fattore-chiave-per-fronteggiare-crisi",
    "https://www.prometeia.it/atlante/che-impatto-avra-pandemia-covid-19-su-climate-change",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/petrolio-che-cosa-ha-portato-prezzo-sottozero-quali-conseguenze",
    "https://www.prometeia.it/atlante/covid-19-emergenti-di-nuovo-su-linea-fuoco-mercati-finanziari-globali",
    "https://www.prometeia.it/atlante/andamento-pil-attenzione-a-confronto-usa-europa",
    "https://www.prometeia.it/ricerca/brief/maggio2020",
    "https://www.prometeia.it/en/atlante/turkish-asset-management-companies-market-consolidation-and-new-entries-on-way",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/impatto-covid-e-recessione-su-occupazione-femminile",
    "https://www.prometeia.it/news/rapporto-di-previsione-luglio-2020-highlight",
    "https://www.prometeia.it/atlante/Next-generation-eu-accordo-storico-che-puo-cambiare-volto-dell-europa",
    "https://www.prometeia.it/news/rapporto-previsione-settembre-2020-highlights",
    "https://www.prometeia.it/ricerca/brief/ottobre2020",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/accordo-rcep-contenuti",
    "https://www.prometeia.it/news/rapporto-previsione-dicembre-2020-highlights",
    "https://www.prometeia.it/atlante/inflazione-rischio-2021",
    "https://www.prometeia.it/atlante/prometeia-brief-italy-global-economy-january-2021",
    "https://www.prometeia.it/atlante/biden-cosa-aspettarsi-dalla-sua-politica-economica",
    "https://www.prometeia.it/atlante/prometeia-brief-italy-global-economy-february-2021",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/come-stanno-principali-manifatture-europee-a-un-anno-scoppio-pandemia",
    "https://www.prometeia.it/ricerca/brief/novembre2020",
    "https://www.prometeia.it/ricerca/brief/luglio2020",
    "https://www.prometeia.it/ricerca/brief/aprile2020",
    "https://www.prometeia.it/ricerca/brief/febbraio2020",
    "https://www.prometeia.it/ricerca/brief/gennaio2020",
    "https://www.prometeia.it/ricerca/brief/ottobre2019",
    "https://www.prometeia.it/ricerca/brief/luglio2019",
    "https://www.prometeia.it/ricerca/brief/aprile2019",
    "https://www.prometeia.it/ricerca/brief/gennaio2019",
    "https://www.prometeia.it/ricerca/position-note/luglio2020",
    "https://www.prometeia.it/ricerca/position-note/marzo2019",
    "https://www.prometeia.it/ricerca/position-note/dicembre2018",
    "https://www.prometeia.it/atlante/russia-exchange-rate-management-de-dollarisation",
    "https://www.prometeia.it/it/trending-topics-article/italia-2030-colma-gap-crescita-con-europa",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/consumi-alimentari-fuori-casa-italia-europa",
    "https://www.prometeia.it/atlante/produzione-industriale-dicembre-2018",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/taglio-crescita-italia-forbice-nord-sud",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/imprese-italiane-aumento-fusioni-acquisizioni-legami-gruppo",
    "https://www.prometeia.it/atlante/italia-hi-tech-per-crescere",
    "https://www.prometeia.it/ricerca/dataviz/perche-italia-non-cresce",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/export-italia-indietro-paesi-alta-qualita-specializzazione",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/export-italia-piu-dinamico-mercato-interno-2018",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/reddito-nuove-generazioni-equilibrio-precario",
    "https://www.prometeia.it/atlante/tempi-pagamento-italia-consigli-normativi-bisogno-credito",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/ecobonus-non-e-per-tutti-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/export-italia-germania-spagna-ultimi-dieci-anni/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/saldo-complessita-estero-come-misura-potenziale-sviluppo",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/export-agroalimentare-a-1-anno-da-ceta",
    "https://www.prometeia.it/ricerca/dataviz/nuovo-indice-incertezza-macroeconomica-per-italia",
    "https://www.prometeia.it/atlante/produzione-industriale-gennaio-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/birra-settore-in-fermento",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/specializzazione-che-hai-paese-nuovo-che-trovi",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/lunga-corsa-verso-estero-imprese-italiane",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/sport-italia-europa-dati-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/digital-economy-spread-italia-germania/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/continua-calo-demografico-italia",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/export-2018-sud-italia-corre-piu-altre-aree",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/italia-sempre-meno-figli-sempre-piu-spese",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/reddito-cittadinanza-cos-e-e-impatto",
    "https://www.prometeia.it/atlante/produzione-industriale-febbraio-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/formazione-continua-driver-competitivita-imprese/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/scalata-cinese-catene-globali-valore/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/atlante/produzione-industriale-marzo-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/laureati-italia-distribuzione-territorio",
    "https://www.prometeia.it/atlante/tensioni-commercio-internazionale-verso-2-trimestre-2019-debole",
    "https://www.prometeia.it/atlante/produzione-industriale-aprile-2019",
    "https://www.prometeia.it/nostra-visione/settori-top-industria-chi-vince-sfida-crescita-triennio-2019-2021",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/mercato-gas-naturale-eccesso-offerta-quotazioni-calo",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/pmi-capisaldi-economia-italiana",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/industria-italiana-conserve-vegetali",
    "https://www.prometeia.it/atlante/produzione-industriale-maggio-2019",
    "https://www.prometeia.it/atlante/terremoti-costi-per-stato-costi-per-ricostruzione",
    "https://www.prometeia.it/nostra-visione/100-imprese-italiane-eccellenti/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/nostra-visione/100-imprese-italiane-eccellenti-22-campioni-meccanica",
    "https://www.prometeia.it/news/analisi-settori-industriali-luglio-2019-highlights",
    "https://www.prometeia.it/atlante/produzione-industriale-luglio-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/100-imprese-italiane-eccellenti-su-territorio-lombardia-emilia-romagna-top-solo-4-sud/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/format-10-best-performer-in-dettaglio",
    "https://www.prometeia.it/ricerca/position-note/settembre2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/fuori-mercato-motivazioni-di-chi-non-cerca-lavoro",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/industria-produzione-industriale-agosto2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/cresce-export-italia-in-uk-tutta-colpa-brexit",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/nadef-2019-scenari-territoriali-confronto/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/atlante/paesi-europei-prova-rivoluzione-verde-ecco-come-si-muove-Italia",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/brand-in-italy-marchi-italiani-sostegno-competitivita-internazionale-paese",
    "https://www.prometeia.it/news/rapporto-analisi-settori-industriali-ottobre-2019-highlights",
    "https://www.prometeia.it/atlante/industria-produzione-industriale-settembre2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/diversificazion-export-lettura-dati-attraverso-termodinamica",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/microsettori-tessuto-imprenditoriale-solido-ma-si-prospettano-sfide-crescenti",
    "https://www.prometeia.it/atlante/produzione-industriale-ottobre-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/mercati-piu-appetibili-export-italiano",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/mercato-auto-italia-punto-su-elettrico",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/imprese-incertezza-condiziona-investimenti",
    "https://www.prometeia.it/atlante/produzione-industriale-novembre-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/20-anni-divari-territoriali-italia",
    "https://www.prometeia.it/prometeiamio/int/regioni-italiane-utilizzo-fondi-europei",
    "https://www.prometeia.it/news/rapporto-analisi-dei-settori-industriali-febbraio-2020-highlights",
    "https://www.prometeia.it/atlante/produzione-industriale-dicembre-2019",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/coronavirus-automotive-italiano-difficolta",
    "https://www.prometeia.it/atlante/coronavirus-in-italia-pil-2020-rivisto-al-ribasso",
    "https://www.prometeia.it/atlante/produzione-industriale-gennaio-2020",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/coronavirus-peso-economico-territori-colpiti-italia",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/coronavirus-lettura-filiera-blocchi-previsti-governo",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/imprese-covid-19-piu-elevata-liquidita-iniziale-bruciata-fretta-da-crisi",
    "https://www.prometeia.it/atlante/produzione-industriale-febbraio-2020",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/economie-dei-territori-tra-attivita-sospese-ipotesi-ripartenza-punto-della-situazione",
    "https://www.prometeia.it/atlante/quali-obiettivi-dovrebbe-porsi-governo-nella-trattativa-europea",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/come-individuare-attivita-produttive-piu-rilevanti-per-funzionamento-intera-economia-italiana",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/coronavirus-italia-effetti-su-modelli-consumo",
    "https://www.prometeia.it/atlante/produzione-industriale-marzo-2020",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/covid-impatto-su-export-regioni-italiane",
    "https://www.prometeia.it/news/rapporto-analisi-settori-industriali-maggio-2020",
    "https://www.prometeia.it/atlante/produzione-industriale-aprile-2020",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/covid-calo-livelli-attivita-per-94-per-cento-microsettori-nel-2020",
    "https://www.prometeia.it/atlante/produzione-industriale-maggio-2020",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/covid-impatto-territorio-italia",
    "https://www.prometeia.it/news/rapporto-analisi-settori-industriali-luglio-2020",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/covid-19-spese-emergenza-regioni-italiane",
    "https://www.prometeia.it/atlante/produzione-industriale-luglio-2020",
    "https://www.prometeia.it/atlante/produzione-industriale-agosto-2020",
    "https://www.prometeia.it/news/rapporto-asi-ottobre-2020-highlight",
    "https://www.prometeia.it/atlante/rimbalzo-pil-3-trimestre-2020",
    "https://www.prometeia.it/atlante/produzione-industriale-settembre-2020",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/75-per-vento-microsettori-fine-2021-fatturato-inferiore-2019",
    "https://www.prometeia.it/atlante/industria-produzione-riprende-slancio-ottobre-2020",
    "https://www.prometeia.it/atlante/prometeia-discussion-note-distributive-effects-covid-italy",
    "https://www.prometeia.it/atlante/industria-produzione-novembre-2020",
    "https://www.prometeia.it/news/analisi-settori-industriali-febbraio-2021",
    "https://www.prometeia.it/atlante/produzione-industriale-dicembre-2020",
    "https://www.prometeia.it/atlante/white-paper-assessing-climate-risks-italian-financial-sector",
    "https://www.prometeia.it/news/one-year-on-economia-italiana-anno-da-scoppio-pandemia",
    "https://www.prometeia.it/atlante/produzione-industriale-gennaio-2021",
    "https://www.prometeia.it/ricerca/position-note/settembre2020",
    "https://www.prometeia.it/ricerca/position-note/settembre2018",
    "https://www.prometeia.it/ricerca/position-note/luglio2018",
    "https://www.prometeia.it/ricerca/position-note/settembre2017",
    "https://www.prometeia.it/ricerca/position-note/luglio2017",
    "https://www.prometeia.it/en/media/neural-networks-motor-insurance-paper",
    "https://www.prometeia.it/news/crowdfunding-e-crowdinvesting-la-situazione-in-Italia",
    "https://www.prometeia.it/news/retail-am-mifid2",
    "https://www.prometeia.it/prometeia/atlante/scorecard-per-individuare-settori-azionari-piu-appetibili",
    "https://www.prometeia.it/atlante/psd2-portabilita-informazioni-conti",
    "https://www.prometeia.it/news/evoluzione-mercato-danni-non-auto-italia",
    "https://www.prometeia.it/news/asset-management-le-nuove-frontiere-automation",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/fotografia-mercato-immobiliare-italiano-2018/L/IT/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/news/life-insurance-proteggere-reddito-familiare-conseguenze-finanziarie-rischi-biometrici",
    "https://www.prometeia.it/atlante/nowcasting-raccolta-netta-fondi-comuni-prosegue-ripresa",
    "https://www.prometeia.it/atlante/nowcasting-prometeia-raccolta-netta-fondi-comuni",
    "https://www.prometeia.it/atlante/liquidita-famiglie-italiane-potenziale-da-sfruttare",
    "https://www.prometeia.it/atlante/nowcasting-prometeia-raccolta-netta-fondi-comuni-novembre",
    "https://www.prometeia.it/atlante/raccolta-netta-fondi-comuni-nowcasting-primi-mesi-2020",
    "https://www.prometeia.it/atlante/coronavirus-quali-effetti-per-il-settore-assicurativo",
    "https://www.prometeia.it/atlante/emergenza-coronavirus-caduta-mercati-anticipa-flessione-raccolta-netta",
    "https://www.prometeia.it/atlante/coronavirus-quali-effetti-su-scelte-investimento-famiglie",
    "https://www.prometeia.it/atlante/emergenza-coronavirus-peggiorano-prospettive-per-raccolta",
    "https://www.prometeia.it/atlante/coronavirus-come-risponderanno-reti-consulenti-finanziari-a-crisi",
    "https://www.prometeia.it/atlante/coronavirus-assicurazioni-prospettive-vita-e-danni",
    "https://www.prometeia.it/atlante/emergenza-coronavirus-aumentano-uscite-dai-fondi-comuni",
    "https://www.prometeia.it/atlante/reti-consulenti-lockdown-volatilita-non-penalizzano-raccolta-netta",
    "https://www.prometeia.it/atlante/fondi-comuni-aprile-rimbalzo-raccolta-netta",
    "https://www.prometeia.it/news/real-estate-business-plan-modelling-via-simulazione-monte-carlo",
    "https://www.prometeia.it/atlante/fondi-comuni-esg-nel-portafoglio-famiglie-offrono-migliori-performance",
    "https://www.prometeia.it/atlante/quanto-conta-digitalizzazione-industria-risparmio",
    "https://www.prometeia.it/atlante/nowcasting-fondi-comuni-primi-segnali-rallentamento-2021",
    "https://www.prometeia.it/atlante/nowcasting-fondi-comuni-buona-partenza-2021",
    "https://www.prometeia.it/financial-advisory/anteo-100",
    "https://www.prometeia.it/financial-advisory/anteo-101",
    "https://www.prometeia.it/financial-advisory/anteo-102",
    "https://www.prometeia.it/news/npl-nuova-asset-class",
    "https://www.prometeia.it/financial-advisory/anteo-103",
    "https://www.prometeia.it/anteo/anteo104",
    "https://www.prometeia.it/anteo/brexit-arrivata-che-sara-ora-regno-unito",
    "https://www.prometeia.it/anteo/angolo-analisi-anteo-febbraio-2020",
    "https://www.prometeia.it/anteo/investimenti-sostenibili-protagonisti-2020-tra-norme-in-evoluzione-crescita-industria",
    "https://www.prometeia.it/anteo/2020-nuovo-decennio-tra-certezze-e-interrogativi",
    "https://www.prometeia.it/anteo/cosa-aspettarsi-dai-mercati-finanziari-nel-2020",
    "https://www.prometeia.it/anteo/investimenti-alternativi-ma-non-troppo",
    "https://www.prometeia.it/anteo/editoriale-anteo-105",
    "https://www.prometeia.it/anteo/geopolitica-faro-dei-mercati",
    "https://www.prometeia.it/anteo/coronavirus-conto-crisi-prospettive-per-mercati-finanziari",
    "https://www.prometeia.it/anteo/azioni-tematiche-per-definizione-di-asset-allocation-moderna",
    "https://www.prometeia.it/anteo/editoriale-anteo-106",
    "https://www.prometeia.it/anteo/covid-19-shock-per-nostre-vite-per-economia-mondiale",
    "https://www.prometeia.it/anteo/angolo-analisi-anteo-aprile-2020",
    "https://www.prometeia.it/anteo/intervista-danilo-giuliani-eppi",
    "https://www.prometeia.it/anteo/coronavirus-cosa-possiamo-imparare-da-cina",
    "https://www.prometeia.it/anteo/private-equity-liquidita-nuovo-paradigma",
    "https://www.prometeia.it/anteo/tassonomia-ue-adempimenti-per-operatori-green-bond-standard-europei",
    "https://www.prometeia.it/news/coco-bonds-analisi-buy-side-e-sell-side",
    "https://www.prometeia.it/anteo/covid-19-risposte-finanza-sostenibile-a-pandemia",
    "https://www.prometeia.it/anteo/investimenti-in-obbligazioni-societarie-europee-sostenibili-alla-prova-covid-19",
    "https://www.prometeia.it/anteo/mercati-finanziari-gia-a-punto-svolta-quali-prospettive-si-celano-dietro-recente-rimbalzo",
    "https://www.prometeia.it/anteo/impatti-covid-19-sui-bisogni-consumi-famiglie",
    "https://www.prometeia.it/anteo/effetto-covid-19-recessione-globale-tempi-recupero-differenziati",
    "https://www.prometeia.it/anteo/editoriale-anteo-107",
    "https://www.prometeia.it/anteo/angolo-analisi-anteo-giugno-2020",
    "https://www.prometeia.it/anteo/cosa-ha-guidato-rimbalzo-maggio2020-prezzo-petrolio",
    "https://www.prometeia.it/atlante/rilancio-italia-ruolo-fondi-pensione",
    "https://www.prometeia.it/anteo/mercato-istituzionale-italiano-peggio-passato",
    "https://www.prometeia.it/anteo/healthcare-prospettive-mercato-investimento",
    "https://www.prometeia.it/anteo/trimestre-estivo-rialzi-mercati-azionari-ottimismo-investitori-tina-quanto-potra-durare-questa-fase",
    "https://www.prometeia.it/anteo/importanza-attivita-engagement-sostenibilita-mercato-italiano-esperienza-italian-sustainability-week",
    "https://www.prometeia.it/anteo/investimenti-tematici-alcune-riflessioni",
    "https://www.prometeia.it/anteo/piano-ripresa-europa-opportunita-sfida-per-Italia",
    "https://www.prometeia.it/anteo/equity-risk-overlay-approcci-tradizionali-approcci-innovativi",
    "https://www.prometeia.it/anteo/angolo-analisi-anteo-settembre-2020",
    "https://www.prometeia.it/anteo/nuova-strategia-ue-finanza-sostenibile-cosa-portera",
    "https://www.prometeia.it/anteo/editorialeanteo108",
    "https://www.prometeia.it/anteo/servizio-ricezione-trasmissione-ordini-importanti-traguardi-nuove-sfide",
    "https://www.prometeia.it/anteo/intervista-massimo-giusti-arco",
    "https://www.prometeia.it/anteo/stewardship-attiva-engagement-proxy-voting-per-approccio-solido-coerente",
    "https://www.prometeia.it/anteo/da-mercati-segnali-maggiore-ottimismo-ritorno-normalita",
    "https://www.prometeia.it/anteo/crisi-covid-riflessi-risultati-primi-nove-mesi-banche-significative-italiane",
    "https://www.prometeia.it/anteo/dalla-ripresa-a-v-nuovi-lockdown-difficile-inverno",
    "https://www.prometeia.it/anteo/composizione-evoluzione-alternative-investment-fund-europei-evidenze-principali-fonti-statistiche",
    "https://www.prometeia.it/anteo/prometeia-advisor-sim-protagonista-finanza-sostenibile",
    "https://www.prometeia.it/anteo/settimana-sri-2020-investitori-previdenziali-fondazioni-origine-bancaria-sempre-piu-interessati-finanza-sostenibile",
    "https://www.prometeia.it/anteo/angolo-dell-analisi-anteo109",
    "https://www.prometeia.it/anteo/editoriale-anteo-109",
    "https://www.prometeia.it/anteo/mercato-asiatico-cos-e-crescita-senza-qualita",
    "https://www.prometeia.it/anteo/inarrestabile-crescita-private-equity-altri-asset-alternativi",
    "https://www.prometeia.it/anteo/primo-bilancio-anno-pandemia",
    "https://www.prometeia.it/anteo/strategie-approcci-esg-esperienze-investitori-esteri",
    "https://www.prometeia.it/anteo/concluso-2020-alcune-considerazioni-passato-sguardo-futuro",
    "https://www.prometeia.it/anteo/angolo-analisi-anteo-febbraio-2021",
    "https://www.prometeia.it/anteo/mercato-istituzionale-italiano-resiliente-pandemia",
    "https://www.prometeia.it/anteo/programma-1800-miliardi-unione-europea-importanza-capitale-privato",
    "https://www.prometeia.it/anteo/investimento-private-equity-valutazione-performance-obiettivi-diversificazione",
    "https://www.prometeia.it/anteo/amministrazione-biden-quali-impatti-su-mercati-equity-bond-us",
    "https://www.prometeia.it/anteo/editoriale-anteo-110",
    "https://www.prometeia.it/anteo/intervista-giuseppe-morandini-presidente-fondazione-friuli",
    "https://www.prometeia.it/anteo/2021-prometeia-advisor-sim",
    "https://www.prometeia.it/news/regolamentazione-e-apertura-volontaria-breve-guida-open-banking-in-Italia-e-estero",
    "https://www.prometeia.it/atlante/mini-bot-moneta-parallela-patrimoniali-occulte",
    "https://www.prometeia.it/atlante/boom-richieste-emissione-btp-50-anni",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/politica-coesione-2021-2027-vincoli-bilancio-nuove-priorita",
    "https://www.prometeia.it/atlante/q-a-su-manovra-bilancio-2020",
    "https://www.prometeia.it/atlante/dove-stanno-andando-borse-mondiali",
    "https://www.prometeia.it/atlante/cosa-e-successo-nel-mercato-repo-usa",
    "https://www.prometeia.it/atlante/basel-4-and-reduction-in-rwa-variability-capital-buffers-and-capital-erosion",
    "https://www.prometeia.it/atlante/coronavirus-borse-scontano-recessione",
    "https://www.prometeia.it/atlante/coronavirus-risposta-autorita-vigilanza-bancaria",
    "https://www.prometeia.it/atlante/panic-selling-da-coronavirus-come-fed-ha-sostenuto-interbancario-usa-in-crisi-liquidita",
    "https://www.prometeia.it/atlante/peltro-bce-che-cosa-e-come-funziona",
    "https://www.prometeia.it/atlante/btp-italia-domanda-retail-record-riscossa-bot-people",
    "https://www.prometeia.it/atlante/covid-proposta-bruxelles-contro-emergenza-economica",
    "https://www.prometeia.it/atlante/text-analysis-su-comunicazione-banche-centrali-ai-tempi-coronavirus",
    "https://www.prometeia.it/atlante/fed-abbandona-tetto-inflazione-per-politica-monetaria-usa-fine-epoca",
    "https://www.prometeia.it/atlante/politicabilancio-2021-governo-italiano",
    "https://www.prometeia.it/atlante/incertezza-breve-termine-penalizza-quotazioni-azionarie-banche-uem",
    "https://www.prometeia.it/atlante/come-usare-politica-bilancio-per-ridurre-costi-transizione-green",
    "https://www.prometeia.it/atlante/gacs-schema-garanzia-statale-italiano-per-cartolarizzazioni-npl",
    "https://www.prometeia.it/atlante/un-anno-dopo-perche-mercati-sono-andati-bene",
    "https://www.prometeia.it/atlante/legge-bilancio-2021-spinta-ma-tra-principali-novita-settore-bancario-italiano",
    "https://www.prometeia.it/ricerca/position-note/dicembre2019",
    "https://www.prometeia.it/ricerca/position-note/luglio2019",
    "https://www.prometeia.it/ricerca/position-note/marzo2018",
    "https://www.prometeia.it/ricerca/position-note/marzo2018/2",
    "https://www.prometeia.it/ricerca/position-note/marzo2017"
]

export const oldPermalinksITA = _oldPermalinksITA.map(item => item.toLowerCase());

const _oldPermalinksENG: string[] = [
    "https://www.prometeia.it/en/research/dataviz/usa-labour-market-strength-worry-for-fed",
    "https://www.prometeia.it/en/atlante/crisi-bancarie-ruolo-sostegno-pubblico?uniq=396fb3e592e63b8710aa47b3ba31cdbc",
    "https://www.prometeia.it/en/atlante/what-is-ester",
    "https://www.prometeia.it/prometeiamio/int/our-vision/cut-italian-growth-north-south-divide",
    "https://www.prometeia.it/atlante/protezionismo-usa-cina-ue-panorama-inizio-2019",
    "https://www.prometeia.it/prometeiamio/int/our-vision/merger-aquisition-increase-among-smes-italy",
    "https://www.prometeia.it/en/news/financial-modelling-insights-the-inflation-index",
    "https://www.prometeia.it/en/news/banks-sailing-in-uncertain-waters",
    "https://www.prometeia.it/en/news/risk-sharing-and-risk-reduction-in-the-euro-area-banking-sector",
    "https://www.prometeia.it/media/neural-networks-motor-insurance-paper",
    "https://www.prometeia.it/en/research/dataviz/why-italy-economy-does-not-grow",
    "https://www.prometeia.it/prometeiamio/int/our-vision/export-italiy-lack-complexity",
    "https://www.prometeia.it/en/atlante/senior-non-preferred-bond-what-are-they",
    "https://www.prometeia.it/prometeiamio/int/our-vision/income-unstable-equilibrium-across-generation",
    "https://www.prometeia.it/en/atlante/late-payments-italy-between-legislative-rules-credit-needs",
    "https://www.prometeia.it/en/news/eu-wide-stress-test-nfci-what-role-for-internal-models",
    "https://www.prometeia.it/en/news/fair-value-of-defaultable-bonds-and-bank-loans-a-practitioners-guide",
    "https://www.prometeia.it/en/news/towards-cfo-cro-collaboration-model-real-challenges-for-banks",
    "https://www.prometeia.it/en/news/basel-3-or-4-where-do-we-stand",
    "https://www.prometeia.it/en/research-insights/brief/february2019",
    "https://www.prometeia.it/en/news/retail-am-mifid2",
    "https://www.prometeia.it/prometeiamio/int/our-vision/italian-incentive-for-ecocars-not-for-all",
    "https://www.prometeia.it/prometeiamio/int/our-vision/usmca-new-nafta",
    "https://www.prometeia.it/en/atlante/fiscal-policy-can-help-countries-adapt-climate-change",
    "https://www.prometeia.it/en/atlante/psd2-account-information-portability",
    "https://www.prometeia.it/en/research/dataviz/new-index-macroeconomic-uncertainty-for-italy",
    "https://www.prometeia.it/en/atlante/crisi-bancarie-ruolo-sostegno-pubblico",
    "https://www.prometeia.it/prometeiamio/int/our-vision/export-specialization-diversification",
    "https://www.prometeia.it/prometeiamio/int/our-vision/italian-firms-powering-ahed-global-trade-race",
    "https://www.prometeia.it/prometeiamio/int/our-vision/sport-italiy-europe-2019-data",
    "https://www.prometeia.it/prometeiamio/int/nostra-visione/demographic-decline-continues-italy",
    "https://www.prometeia.it/en/news/ECB-liquidity-stress-test-2019-white-paper",
    "https://www.prometeia.it/prometeiamio/int/our-vision/regional-disparities-italian-or-european-pain",
    "https://www.prometeia.it/en/research/dataviz/cars-export-shares-to-usa-2019",
    "https://www.prometeia.it/prometeiamio/int/our-vision/china-rise-global-value-chains",
    "https://www.prometeia.it/en/research-insights/brief/may2019",
    "https://www.prometeia.it/en/research/dataviz/how-much-member-countries-public-debt-is-held-by-the-official-sector-and-why-this-is-important",
    "https://www.prometeia.it/prometeiamio/int/our-vision/trade-war-us-china-italy-role",
    "https://www.prometeia.it/prometeiamio/int/our-vision/graduate-italy-distribution-territory",
    "https://www.prometeia.it/en/atlante/62-years-after-treaty-rome-why-we-still-need-europe",
    "https://www.prometeia.it/prometeiamio/int/our-vision/smes-cornerstones-italy-economy/L/EN/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/en/news/how-will-credit-spread-risk-in-the-banking-book-be-put-into-practice-prometeia-survey",
    "https://www.prometeia.it/en/news/highlights-quarterly-economic-outlook-prometeia-july-2019",
    "https://www.prometeia.it/prometeiamio/int/our-vision/polish-economy-confirmed-strong/L/EN/UT/MIO-nostraVisione-articolo",
    "https://www.prometeia.it/en/news/npl-new-asset-class",
    "https://www.prometeia.it/en/news/does-digitalisation-increase-bank-profitability",
    "https://www.prometeia.it/en/news/why-italian-banks-pay-higher-returns-to-investors",
    "https://www.prometeia.it/ricerca/position-note/september2019",
    "https://www.prometeia.it/en/news/quarterly-economic-outlook-september-2019-highlights",
    "https://www.prometeia.it/en/atlante/european-countries-green-revolution-challenge-how-is-italy-reacting",
    "https://www.prometeia.it/en/atlante/tltro-iii-fund-when-is-best-moment-to-knock-ecb-door",
    "https://www.prometeia.it/en/research-insights/brief/november2019",
    "https://www.prometeia.it/en/atlante/do-low-interest-rates-really-hurt-banks-profitability",
    "https://www.prometeia.it/en/news/how-banks-respond-ECB-validation-reporting-model-risk-management-requirements-findings",
    "https://www.prometeia.it/en/atlante/basel-4-and-reduction-in-rwa-variability-capital-buffers-and-capital-erosion",
    "https://www.prometeia.it/en/dataviz/what-weakens-germany-exports",
    "https://www.prometeia.it/en/atlante/coronavirus-in-italy-gdp-2020-revised-downwards",
    "https://www.prometeia.it/en/atlante/ai-contribution-to-fighting-the-coronavirus",
    "https://www.prometeia.it/en/atlante/coronavirus-emergency-blunt-moves-of-ecb",
    "https://www.prometeia.it/en/atlante/coronavirus-emergency-how-to-best-use-new-ecb-liquidity",
    "https://www.prometeia.it/en/news/quarterly-economic-outlook-march-2020",
    "https://www.prometeia.it/en/atlante/what-do-financial-markets-tell-us-about-banking-sectorrisks",
    "https://www.prometeia.it/en/news/coco-bonds-a-buy-side-and.sell-side-prices-and-returns-analysis",
    "https://www.prometeia.it/en/atlante/impact-covid-19-pandemic-on-climate-change",
    "https://www.prometeia.it/prometeiamio/int/our-vision/what-does-sub-zero-oil-price-mean-for-energy-industry-and-economy",
    "https://www.prometeia.it/en/research-insights/brief/may2020",
    "https://www.prometeia.it/en/atlante/turkish-asset-management-companies-market-consolidation-and-new-entries-on-way",
    "https://www.prometeia.it/prometeiamio/int/our-vision/implications-covid-and-this-recession-on-female-employment",
    "https://www.prometeia.it/en/news/real-estate-business-plan-modelling-via-monte-carlo-simulation-white-paper",
    "https://www.prometeia.it/en/atlante/what-does-text-analysis-tell-us-about-central-banks-communication-in-times-covid",
    "https://www.prometeia.it/en/atlante/italian-banks-six-new-trends-driven-by-covid-19",
    "https://www.prometeia.it/en/research-insights/brief/october2020",
    "https://www.prometeia.it/news/ai-risk-management-fattori-abilitanti-use-case-sfide-future",
    "https://www.prometeia.it/en/atlante/fiscal-tools-reduce-transition-costs-climate-change-mitigation",
    "https://www.prometeia.it/en/news/climate-change-risk-across-EU-banking-system-prometeia-survey",
    "https://www.prometeia.it/en/atlante/central-bank-digital-currencies-what-is-at-stake-fot-the-banking-system",
    "https://www.prometeia.it/en/atlante/prometeia-discussion-note-distributive-effects-covid-italy",
    "https://www.prometeia.it/atlante/en/chance-inflation-rearing-head-again",
    "https://www.prometeia.it/atlante/en/prometeia-brief-italy-global-economy-january-2021",
    "https://www.prometeia.it/en/atlante/prometeia-brief-italy-global-economy-february-2021",
    "https://www.prometeia.it/en/atlante/white-paper-assessing-climate-risks-italian-financial-sector",
    "https://www.prometeia.it/en/news/credit-risk-models-the-power-of-transactional-data-and-machine-learning",
    "https://www.prometeia.it/en/research-insights/brief/november2020",
    "https://www.prometeia.it/en/research-insights/brief/july2020",
    "https://www.prometeia.it/en/research-insights/brief/april2020",
    "https://www.prometeia.it/en/research-insights/brief/february2020",
    "https://www.prometeia.it/en/research-insights/brief/january2020",
    "https://www.prometeia.it/en/research-insights/brief/october2019",
    "https://www.prometeia.it/en/research-insights/brief/july2019",
    "https://www.prometeia.it/en/research-insights/brief/april2019",
    "https://www.prometeia.it/en/research-insights/brief/January2019",
    "https://www.prometeia.it/en/research/position-note/september2020",
    "https://www.prometeia.it/en/research/position-note/july2020",
    "https://www.prometeia.it/en/research/position-note(december2019",
    "https://www.prometeia.it/en/research/position-note/july2019",
    "https://www.prometeia.it/en/research/position-note/march2019",
    "https://www.prometeia.it/en/research/position-note/december2018",
    "https://www.prometeia.it/en/research/position-note/september2018",
    "https://www.prometeia.it/en/research/position-note/july2018",
    "https://www.prometeia.it/en/research/position-note/march2018",
    "https://www.prometeia.it/en/research/position-note/march2018/2",
    "https://www.prometeia.it/en/research/position-note/september2017",
    "https://www.prometeia.it/en/research/position-note/july2017",
    "https://www.prometeia.it/en/research/position-note/march2017",
    "https://www.prometeia.it/en/atlante/russia-exchange-rate-management-de-dollarisation",
    "https://www.prometeia.it/en/trending-topics-article/italy-2030-closes-growth-gap-with-europe"
]

export const oldPermalinksENG = _oldPermalinksENG.map(item => item.toLowerCase());